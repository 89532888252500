const config = {
SELLER_PROFILE_API_URL: 'https://sellerprofileservicephase2.shipsy.io', //demo
BANNER_IMAGE_URL: 'https://shipsy-public-assets.s3.amazonaws.com/generic/file-11896d4b-2a2d-4861-92e8-28710f12841c.jpeg',
    CAROUSEL_CONFIG: [
        {
            "image_link": "https://shipsy-generic-file-upload-dtdc-prod.s3.amazonaws.com/banner/1/2024-11-05/f407518d68d4475088ae1a98ea30f0e4",
        },
        {
            "image_link": "https://shipsy-generic-file-upload-dtdc-prod.s3.amazonaws.com/banner/1/2024-11-05/1bcab88a211e4b03bde5fadfaf3c2f24",
        },
        {
            "image_link": "https://shipsy-generic-file-upload-dtdc-prod.s3.amazonaws.com/banner/1/2024-11-05/988eaf077e82446fbe4eb6be36d506e3",
        }
    ],
};
export default config;
